var datePicker = {
    datePickerInit: function() {
        var $datePickerWrap = $('.datepicker'),
        	currentDate = new Date();

        if (!$datePickerWrap.length) {
            return;
        }

        bookingDateFunction();

        function bookingDateFunction() {
            if ($('*').is('.book-form')) {
                $('.book-form').each(function() {
                    var self = $(this);
                    var t = $,
                        e = 'yyyy-mm-dd',
                        //e = 'd mmmm, yyyy',
                        i = null,
                        n = null,
                        o = new Date,
                        r = new Date;

                    r.setDate(o.getDate() + 1);
                    self.find(".arrivalDate").pickadate({
                        formatSubmit: e,
                        min: o,
                        hiddenName: !0,
                        onStart: function() {
                            i = this, self.find(".arrDateIcon").on("click", function(t) {
                                t.stopPropagation(), i.open()
                            })
                        },
                        onSet: function(t) {
                            if (t.select) {
                                var e = this.get("select"),
                                    o = e.obj,
                                    a = n.get("select");
                                var z = new Date(o);
                                void 0 != a && (a = a.obj, o > a && (a.setDate(o.getDate() + 1), n.set("select", a))), z.setTime(z.getTime() + 864e5), s.set("min", z), i.close()
                            }

                            return void 0 !== t.clear ? (s.set("min", r), void i.close()) : void 0
                        }
                    });
                    var a = self.find(".arrivalDate").pickadate("picker");

                    self.find(".departureDate").pickadate({
                        formatSubmit: e,
                        hiddenName: !0,
                        min: r,
                        onStart: function() {
                            n = this, self.find(".depDateIcon").on("click", function(t) {
                                t.stopPropagation(), n.open()
                            })
                        },
                        onSet: function(t) {
                            if (t.select) {
                                var e = this.get("select"),
                                    o = e.obj,
                                    r = i.get("select");
                                var z = new Date(o);
                                void 0 != r && (r = new Date(r.obj), r > o && (r.setDate(r.getDate() + 1), n.set("select", r))), z.setTime(z.getTime() - 864e5), a.set("max", z), n.close()
                            }
                            void 0 !== t.clear && (a.set("max", !1), i.close())
                        }
                    });
                    var s = self.find(".departureDate").pickadate("picker");
                });
            };
        };

    }

};

export default datePicker;