// You can write a call and import your functions in this file.
//
// This file will be compiled into app.js and will not be minified.
// Feel free with using ES6 here.

import scrollDown from './modules/scroll-down';
import heroSlider from './modules/hero-slider';
import simpleSlider from './modules/simple-slider';
import burger from './modules/burger';
import customSelect from './modules/custom-select';
import datePicker from './modules/datepicker';
import bookingToggle from './modules/booking-toggle';
import agreeModal from './modules/agree-modal';
import submenu from './modules/submenu';

( ($) => {
  'use strict';

  // When DOM is ready
  $(() => {
    scrollDown.scrollDownInit();
    heroSlider.heroSliderInit();
    simpleSlider.simpleSliderInit();
    burger.burgerInit();
    customSelect.customSelectInit();
    datePicker.datePickerInit();
    bookingToggle.bookingToggleInit();
    submenu.submenuInit();
  });

  agreeModal.agreeModalInit();

})(jQuery);