var agreeModal = {
    agreeModalInit: function() {
        var $agreeModalWrap = $('#agree_modal'),
            $agreeBtn = $agreeModalWrap.find('.agree-btn');

        if (!$agreeModalWrap.length) {
            return;
        }

        $agreeBtn.on('click', agreeSet);

        $agreeModalWrap.modal({
        	backdrop: 'static',
        	keyboard: false
        });

        function agreeSet(){
            $.cookie('agree_cookie', 'yes');
            $('#agree_modal').modal('hide');
        }

    }

};

export default agreeModal;