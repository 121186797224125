var heroSlider = {
    heroSliderInit: function() {
        var $heroSliderWrap = $('.hero-slider');

        if (!$heroSliderWrap.length) {
            return;
        }

        var swiper = new Swiper('.hero-slider', {
	        spaceBetween: 0,
	        autoplay: 5000,
	        autoplayDisableOnInteraction: true,
	        effect: 'fade'
	    });

    }

};

export default heroSlider;