var bookingToggle = {
    bookingToggleInit: function() {
        var $bookBtn = $('.book-holder .book-btn'),
            $bookHolder = $('.book-holder .book-wrap'),
            $bookCloseBtn = $('.book-holder .close');

        if (!$bookBtn.length) {
            return;
        }

        $bookBtn.on('click', function(e){
            e.preventDefault();
            bookShowHandler();
        });

        $bookCloseBtn.on('click', bookCloseHandler);

        $('body').on('click', bookCloseHandler);

        $bookHolder.bind("click", function(e) {
            stop_bubbling(e);
        });

        $bookBtn.bind("click", function(e) {
            stop_bubbling(e);
        });

        function bookShowHandler(){
          $bookHolder.addClass('active');
        }

        function bookCloseHandler(){
          $bookHolder.removeClass('active');
        }

        function stop_bubbling(e) {
            if (e.stopPropagation) e.stopPropagation();
            else e.cancelBubble = true;
        }

    }

};

export default bookingToggle;