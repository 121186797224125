var simpleSlider = {
    simpleSliderInit: function() {
        var $simpleSliderWrap = $('.simple-slider');

        if (!$simpleSliderWrap.length) {
            return;
        }

        var swiper = new Swiper('.simple-slider .swiper-container', {
	        pagination: '.simple-slider .swiper-pagination',
	        paginationClickable: true,
            autoplay: 5000,
            autoHeight: true,
            autoplayDisableOnInteraction: false
	    });

    }

};

export default simpleSlider;